import React, { useEffect, useState } from "react"
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery";

const MainMenu = (props) => {
  const [showDropDown,setShowDropDown] = useState(-1);
  const data = useStaticQuery(graphql`
    query HeaderMenuQuery {
      glstrapi {
        burgerMenus(sort: "Sort") {
          Parent_Label
          Custom_Link
          Parent_URL {
            Primary_URL
          }
          Burger_Sub_Menu {
            Custom_Link
            Parent_Label
            Parent_URL {
              Primary_URL
            }
            Add_Sub_Menu {
              Custom_Link
              Label
              URL {
                Primary_URL
              }
            }
          }
          Intro_Title
          Intro_Text
          Intro_Button
          Intro_Button_Link
        }
      }
    }
  `)

  const getMenuSlug = (url, getSecondary = false) => {
    if (url?.Primary_URL && getSecondary) {
      return url?.Primary_URL;
    }
  }
  const getMenuUrl = (menus, secondaryUrl, customUrl) => {
    let menuUrl = "";
    if (customUrl) {
      menuUrl = customUrl;
    }else if (secondaryUrl) {
      menuUrl = secondaryUrl;
    } else if (menus) {
      menus.map((mnu, i) => {
        if (mnu) {
          menuUrl = `${menuUrl}${getMenuSlug(mnu, false)}/`
        }
      })
    }
    return menuUrl;
  }

  const showSubmenu = (e) => {
    e.preventDefault();
    //console.log("event", e.target.parentElement);
    $(e.target).toggleClass('actv');
    $('.mega-menu', e.target.parentElement).toggle('fade');
  }

  const showSecSubmenu = (e) => {
    e.preventDefault();
    //console.log("event", e.target.parentElement);
    $(e.target).toggleClass('actv');
    $('.sub-menu', e.target.parentElement.parentElement).toggle('fade');
  }
  const triggerClick = (e) => {
    e.preventDefault();
    $(e.target).next('.plus-icon').trigger('click');
  }

  useEffect(()=>{
    $(".popup-btn").on( "click", function() {
        var element_id = $(this).attr('id');
        if ( element_id == '#find-property-residential' ) {
            setTimeout(function() {
                $(".service-menu.open-service .nav-tabs .nav-item:last-child").attr('id', 'residentialtab');
            }, 100);
            setTimeout(function() {
                document.getElementById('residentialtab').click();
            }, 300);
        }
    });
  },[])

  const menuData = data
  var burgerMenuDatas = menuData.glstrapi.burgerMenus

  return (
    <div className="menu">
        <nav className="navigation-menu">
            <ul className="navigation-list">
                {burgerMenuDatas && burgerMenuDatas.map((menuData, key) => {
                    // console.log('burgerMenuDatas', burgerMenuDatas)
                let burgerSubMenu = menuData.Burger_Sub_Menu;
                let isburgerSubMenu = burgerSubMenu.length > 0
                let subMenus = burgerSubMenu.filter(key2 => key2.Add_Sub_Menu && key2.Add_Sub_Menu?.length > 0)
                let isSubMenu = subMenus.length > 0
                let checkLink = `/${menuData.Intro_Button_Link}`
                let checkClick = ''
                if ( menuData.Intro_Button_Link == '#find-property-commercial' || menuData.Intro_Button_Link == '#find-property-residential' || menuData.Intro_Button_Link == '#find-property' ) {
                    checkLink = 'javascript:void(0);';
                    checkClick = 'popup';
                }
                return (
                    <li className={`parent_menu ${isburgerSubMenu > 0 ? 'has-submenu' : ''}`} id={''}>
                    {menuData.Custom_Link == "#" ?
                        <a href="javascript:;">{menuData.Parent_Label}</a>
                    :
                        <Link to={`/${getMenuUrl([menuData.Parent_URL], menuData.Parent_URL?.Primary_URL, menuData.Custom_Link)}`} className={""}>{menuData.Parent_Label} </Link>
                    }
                    {isburgerSubMenu ? <i className="plus-icon top-icon" onClick={showSubmenu}></i> : ''}
                    <div className={`mega-menu ${isSubMenu ? 'fullwidth-menu' : 'fullwidth-menu'}`}>
                        <div className={`mega-menu-wrap ${isSubMenu ? 'fullwidth-menu-wrap' : 'fullwidth-menu-wrap'}`}>
                        {
                            isSubMenu ?
                            <div className="burger-menu-wrap">
                                {menuData.Intro_Title ?
                                    <>
                                    <div className="sub-menu-wrap intro-section">
                                        <h3>{menuData.Intro_Title ? menuData.Intro_Title : '' }</h3>
                                        <p>{menuData.Intro_Text ? menuData.Intro_Text : '' }</p>
                                        {checkClick == 'popup' ?
                                            <a className="btn-primary popup-btn" id={menuData.Intro_Button_Link} href={checkLink} onClick={()=>{props.openSearch('Find a property')}}>
                                                {menuData.Intro_Button ? menuData.Intro_Button : '' }
                                            </a>
                                        :
                                            <a className="btn-primary" href={checkLink}>
                                                {menuData.Intro_Button ? menuData.Intro_Button : '' }
                                            </a>
                                        }
                                    </div>
                                    </>
                                : ''}
                                {isburgerSubMenu && burgerSubMenu.map((subMenu, index) => {
                                return (
                                    <div className="sub-menu-wrap">
                                    <div className={'h3'}>
                                    {subMenu.Custom_Link == "#" ?
                                    <a href="javascript:;">{subMenu.Parent_Label}</a>
                                    :
                                    <Link to={`/${getMenuUrl([menuData.Parent_URL, subMenu.Parent_URL], subMenu.Parent_URL?.Primary_URL, subMenu.Custom_Link)}`}>{subMenu.Parent_Label}</Link>
                                    }
                                        {subMenu.Add_Sub_Menu.length > 0 ? <i className="plus-icon" onClick={showSecSubmenu}></i> : ''}
                                        <ul className="sub-menu">
                                        {subMenu.Add_Sub_Menu && subMenu.Add_Sub_Menu.map((subMenu1, index) => {
                                            return (
                                            <li className={`primary_submenu`}>
                                                {
                                                subMenu1?.URL?.Primary_URL && subMenu1?.URL?.Primary_URL === "javascript:;" ?
                                                    <a href="javascript:;" onClick={triggerClick}>- {subMenu1.Label}</a>
                                                    :
                                                    <Link to={`/${getMenuUrl([menuData.Parent_URL, subMenu.Parent_URL, subMenu1.URL], subMenu1.URL?.Primary_URL, subMenu1.Custom_Link)}`}>- {subMenu1.Label}</Link>
                                                }
                                            </li>
                                            )
                                        })}

                                        </ul>
                                    </div>
                                    </div>)
                                })
                                }
                            </div>
                            :
                            (
                              <div className="burger-menu-wrap">
                                {menuData.Intro_Title ?
                                  <div className="sub-menu-wrap intro-section">
                                      <h3>{menuData.Intro_Title ? menuData.Intro_Title : '' }</h3>
                                      <p>{menuData.Intro_Text ? menuData.Intro_Text : '' }</p>
                                      { menuData.Intro_Button ?
                                        <>
                                          {checkClick == 'popup' ?
                                            <a className="btn-primary popup-btn" id={menuData.Intro_Button_Link} href={checkLink} onClick={()=>{props.openSearch('Find a property')}}>
                                                {menuData.Intro_Button ? menuData.Intro_Button : '' }
                                            </a>
                                          :
                                              <a className="btn-primary" href={checkLink}>
                                                  {menuData.Intro_Button ? menuData.Intro_Button : '' }
                                              </a>
                                          }
                                        </>
                                      : ""
                                      }
                                  </div>
                                : ''}
                                <ul className="sub-menu-wrap">
                                    {isburgerSubMenu && burgerSubMenu.map((subMenu, index) => {
                                    return (<li><div className={'h3'}><Link to={`/${getMenuUrl([menuData.Parent_URL, subMenu.Parent_URL], subMenu.Parent_URL?.Primary_URL, subMenu.Custom_Link)}`}>{subMenu.Parent_Label}</Link></div></li>)
                                    })
                                    }
                                </ul>
                              </div>
                            )
                        }
                        </div>
                    </div>
                    </li>
                )

                })}
                <a className="btn-primary property-search-btn" href="javascript:;" onClick={()=>{props.openSearch('Find a property')}}>Find a property</a>
            </ul>
        </nav>
        <a href="tel:020 8520 9911" className="d-lg-none phone_number" ><i className="icon-phone"></i></a>
        <strong onClick={()=>{props.openSearch("Site Search")}} className="search-menu">
            <i className={`icon-search-white ${props.search ? " open" : ""}`}></i>
        </strong>

        <button onClick={props.openHam} className={props.open ? "hamburger d-lg-none close" : "hamburger d-lg-none"}>
            <span></span>
            <span></span>
            <span></span>
        </button>
    </div>
  );
};
export default MainMenu;
